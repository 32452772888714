<template>
  <div class="mobile-simulation">
    <div class="pub-name">{{ currentAppName }}</div>
    <div class="bottom-menus">
      <div class="menu-editor">
        <div
          v-for="item in menus"
          :key="item.id || item.key"
          class="top-menus-add top-menu"
        >
          <div v-if="item.showChildren" class="popper">
            <el-button
              v-for="subMenu in item.sub_button"
              :key="subMenu.id || subMenu.key"
              class="sub-menu"
              @click="clickSubMenu(subMenu)"
              >{{ subMenu.name }}</el-button
            >
            <el-button
              v-if="item.sub_button.length < 5"
              class="sub-menu"
              icon="el-icon-plus"
              @click="addSubMenu(item)"
            ></el-button>
          </div>

          <el-button
            style="height: 100%; width: 100%"
            @click="clickTopMenu(item)"
          >
            {{ item.name }}
          </el-button>
        </div>

        <div v-if="menus.length < 3" class="top-menus-add">
          <el-button
            style="width: 100%; height: 100%; border-radius: 0;"
            icon="el-icon-plus"
            @click="addTopMenu"
          >添加菜单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonComputeds from '@/utils/commonComputeds'
export default {
  name: "mobile-simulation",
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...commonComputeds
  },
  methods: {
    addTopMenu() {
      if (this.currentAppId <= 0) {
        this.$message.warning('请先在右上角选择应用后再操作');
        return;
      }
      this.$emit("add-top-menu");
    },
    addSubMenu(topMenu) {
      this.$emit("add-sub-menu", topMenu);
    },
    clickTopMenu(topMenu) {
      this.menus.forEach(item => {
        if (topMenu !== item) {
          this.$set(item, 'showChildren', false);
        } else {
          this.$set(item, 'showChildren', true);
        }
      });
      this.$emit("click-top-menu", topMenu);
    },
    clickSubMenu(subMenu) {
      this.$emit("click-sub-menu", subMenu);
    },
  },
};
</script>

<style lang="less">
.mobile-simulation {
  height: 95%;
  width: 319px;
  background: url("~@/assets/wx/pub_background_top.png") no-repeat;
  display: flex;
  flex-direction: column;
  border: @hair-border-ccc;

  .pub-name {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    padding: 2rem;
    color: white;
  }

  .bottom-menus {
    background: url("~@/assets/wx/pub_background_bottom.png") no-repeat;
    position: relative;
    bottom: -0.5px;
    height: 100%;
    border-radius: 0;
    background-position-y: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .menu-editor {
      width: 276px;
      float: right;
      height: 50px;
      display: flex;

      .top-menu {
        position: relative;
        flex: 1;
        button {
          padding: 0px;
          border-radius: 0;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        
        .popper {
          position: absolute;
          width: 90%;
          left: 5%;
          top: -230px;
          height: 220px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          .sub-menu {
            width: 100%;
            height: 44px;
            margin-left: 0px;
            border-radius: 0;
            font-size: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .top-menus-add {
        height: 100%;
        flex: 1;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>